// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xmWWC5zN4Ohnk6ZuaROF{width:100%;height:100%;position:relative}.xmWWC5zN4Ohnk6ZuaROF .mX4uG4ORXFlm91DxEmNX{display:flex;flex-direction:row;width:100%;height:100%;overflow-x:auto;scroll-snap-type:x mandatory;scroll-behavior:smooth;-ms-overflow-style:none;scrollbar-width:none}.xmWWC5zN4Ohnk6ZuaROF .mX4uG4ORXFlm91DxEmNX:-webkit-scrollbar{display:none}.xmWWC5zN4Ohnk6ZuaROF .mX4uG4ORXFlm91DxEmNX .HJlFVQAqpgFHXoX3xcQS{width:100%;height:100%;flex:0 0 100%;scroll-snap-align:start;overflow:hidden}.xmWWC5zN4Ohnk6ZuaROF .i_c2fUFxDns3uhGywbQX{position:absolute;bottom:32px;left:50%;width:max-content;max-width:100%;height:24px;z-index:9;transform:translateX(-50%)}.xmWWC5zN4Ohnk6ZuaROF .i_c2fUFxDns3uhGywbQX .fQybbcLWvOZ661nACXDp{width:12px;height:12px;margin:0 8px 0 8px;border-radius:50%;background-color:#9ca3af;cursor:pointer}.xmWWC5zN4Ohnk6ZuaROF .i_c2fUFxDns3uhGywbQX .fQybbcLWvOZ661nACXDp.JToozD_dwkLyuL8HAalZ{background-color:#fff}.xmWWC5zN4Ohnk6ZuaROF .i_c2fUFxDns3uhGywbQX .fQybbcLWvOZ661nACXDp.Fdau9dbYR6djKZ0xt1AL.JToozD_dwkLyuL8HAalZ{background-color:#434b4f}.xmWWC5zN4Ohnk6ZuaROF .U73hnpYy5uwL9QKbK4GY,.xmWWC5zN4Ohnk6ZuaROF .F3397_OvRUC76reTi3qG{position:absolute;top:50%;width:48px;height:48px;border-radius:50%;background-color:#434b4f;cursor:pointer;transform:translateY(-50%);background-position:center}.xmWWC5zN4Ohnk6ZuaROF .F3397_OvRUC76reTi3qG{left:32px;transform:translateY(-50%) rotate(180deg);display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Next-cloud.svg")}.xmWWC5zN4Ohnk6ZuaROF .U73hnpYy5uwL9QKbK4GY{right:32px;display:inline-block;background-repeat:no-repeat;content:"";background-image:url("../assets/icons/Next-cloud.svg")}`, ""]);
// Exports
export var carousel = `xmWWC5zN4Ohnk6ZuaROF`;
export var hiddenContainer = `mX4uG4ORXFlm91DxEmNX`;
export var carouselNode = `HJlFVQAqpgFHXoX3xcQS`;
export var bottomButtons = `i_c2fUFxDns3uhGywbQX`;
export var bottomButton = `fQybbcLWvOZ661nACXDp`;
export var active = `JToozD_dwkLyuL8HAalZ`;
export var darkIndicators = `Fdau9dbYR6djKZ0xt1AL`;
export var rightButton = `U73hnpYy5uwL9QKbK4GY`;
export var leftButton = `F3397_OvRUC76reTi3qG`;
export default ___CSS_LOADER_EXPORT___;
